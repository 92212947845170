<!-- eslint-disable vue/html-closing-bracket-newline -->
<template>
  <div>
    <b-card>
      <b-card-text>
        Chocolate cake icing tiramisu liquorice toffee donut sweet roll cake.
        Cupcake dessert icing dragée dessert. Liquorice jujubes cake tart pie
        donut. Cotton candy candy canes lollipop liquorice chocolate marzipan
        muffin pie liquorice.
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import { BTabs, BTab, BCard, BCardText, BLink } from "bootstrap-vue";
export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BTabs,
    BTab,
  },
};
</script>

<style></style>
